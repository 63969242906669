import React, { useState } from "react";
import * as Yup from "yup";
import { Box, Text } from "@chakra-ui/react";
import { useFormik } from "formik";

import { submitForm } from "../fetchers";

import Form from "../components/Form";
import FormSubmitted from "../components/FormSubmitted";

const clientReferralSchema = Yup.object().shape({
  opportunityEMRID: Yup.string()
    .optional()
    .meta({ order: 1, label: "Opportunity EMR ID" }),
  therapistName: Yup.string()
    .optional()
    .meta({ order: 2, label: "Referring Clinician: Name" }),
  therapistEmail: Yup.string()
    .email("Invalid email")
    .required("Required")
    .meta({ order: 3, label: "*Referring Clinician: Email" }),
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .meta({ order: 4, label: "*Patient: First name" }),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .meta({ order: 5, label: "*Patient: Last name" }),
  email: Yup.string()
    .email("Invalid email")
    .required("Required")
    .meta({ order: 6, label: "*Patient: Email" }),
  phone: Yup.string().optional().meta({ order: 7, label: "Patient: Phone" }),
  sexAtBirth: Yup.string()
    .required("Required")
    .oneOf(["Male", "Female"])
    .meta({
      order: 8,
      label: "*Patient: Sex at birth",
      placeholder: "Select",
      variant: "select",
      options: [
        {
          label: "Female",
          value: "Female"
        },
        {
          label: "Male",
          value: "Male"
        }
      ]
    }),
  state: Yup.string()
    .required("Required")
    .oneOf(["CA", "NY"])
    .meta({
      order: 9,
      variant: "select",
      label: "*Patient: State",
      placeholder: "Select state",
      options: [
        {
          label: "California",
          value: "CA"
        },
        {
          label: "New York",
          value: "NY"
        }
      ]
    }),
  insurancePayerName: Yup.string().optional().meta({
    order: 10,
    label: "Insurance:  Payer Name"
  }),
  insurancePlanName: Yup.string().optional().meta({
    order: 11,
    label: "Insurance: Plan Name"
  }),
  insurancePayerId: Yup.string().optional().meta({
    order: 12,
    label: "Insurance: Payer ID"
  }),
  insuranceMemberId: Yup.string().optional().meta({
    order: 13,
    label: "Insurance: Member ID"
  }),
  informationOnPsychiatryNeeds: Yup.string().optional().meta({
    order: 14,
    label: "Patient: Information on psychiatry needs",
    placeholder: "Please provide any information on psychiatry needs",
    variant: "textarea"
  })
});

const InPatientFormPage = () => {
  const [state, setState] = useState({
    errorMsg: "",
    loading: false,
    sent: false,
    startTs: new Date().getTime()
  });
  const formik = useFormik({
    initialValues: {
      informationOnPsychiatryNeeds: "",
      insuranceMemberId: "",
      insurancePayerName: "",
      insurancePayerId: "",
      insurancePlanName: "",
      opportunityEMRID: "",
      therapistName: "",
      therapistEmail: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      sexAtBirth: "",
      state: ""
    },
    validationSchema: clientReferralSchema,
    onSubmit: async (values) => {
      setState((s) => ({ ...s, loading: true }));

      const cleanEmail = values?.["email"].trim().toLowerCase() || "";
      const strippedNumber = values?.["phone"].replace(/\D/g, "") || "";

      const result = await submitForm({
        formId: "rl-referral",
        formVersion: "v1",
        formData: { ...values, email: cleanEmail, phone: strippedNumber },
        startTs: state.startTs,
        endTs: new Date().getTime()
      });
      if (result.success) {
        setState((s) => ({ ...s, sent: true }));
      } else if (result.status === 429) {
        setState((s) => ({
          ...s,
          errorMsg:
            "Too many requests from your network, please try again later"
        }));
      } else {
        setState((s) => ({
          ...s,
          errorMsg: "Something went wrong, please try again or reach out"
        }));
      }

      setState((s) => ({ ...s, loading: false }));
    }
  });

  if (state.sent) {
    return <FormSubmitted />;
  }

  return (
    <>
      <Box bg="#cee0da" w="100%" p={4} color="black">
        <Text className="rl_dm_sans" fontSize="24px">
          Refer a patient to Options MD
        </Text>
      </Box>
      <Box sx={{ textAlign: "center", padding: 2 }}>
        <Text color="tomato" fontSize="lg">
          {state.errorMsg}
        </Text>
      </Box>
      <Form
        formik={formik}
        formProps={{
          sx: {
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: 400
          }
        }}
        submitButtonLabel="Submit"
        submitButtonProps={{
          colorScheme: "blue",
          sx: {
            marginTop: 2
          }
        }}
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: 10
        }}
        submitLoading={state.loading}
        validationSchema={clientReferralSchema}
      />
      <Text margin="0 auto 32px" maxW={"400px"}>
        Upon form submission the patient will receive an email and an sms text
        message with a link to get started with registration and onboarding to
        Options MD.
      </Text>
    </>
  );
};

export default InPatientFormPage;
